@font-face {
    font-family: 'batman';
    src: url('../assets/fonts/batmfa__.ttf') format('truetype');
  }

@font-face {
  font-family: 'philosopher';
  src: url('../assets/fonts/Philosopher-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'parisienne';
  src: url('../assets/fonts/Parisienne-Regular.ttf') format('truetype');
}

.backgroundLayer {
  position: absolute; /* Asegúrate de que la capa esté posicionada correctamente */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Asegúrate de que la capa ocupe toda la altura de la página */
  background-size: cover; /* Asegura que la imagen cubra toda la capa */
  background-position: center; /* Centra la imagen dentro de la capa */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  z-index: -1; /* Asegúrate de que el contenido esté por encima de la imagen de fondo */
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 2rem;
  color: #fff; /* Ajusta el color del texto según sea necesario */
  text-align: center;
}